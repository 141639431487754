let hamburgerModal = null;
let pagetopButton = null;
let isSP = false;
let isTab = false;

const initDevice = () => {
  pagetopButton = document.getElementById("top-pagetop");
  if (
    navigator.userAgent.indexOf("iPhone") > 0 ||
    (navigator.userAgent.indexOf("Android") > 0 &&
      navigator.userAgent.indexOf("Mobile") > 0)
  ) {
    // device = sp
    isSP = true;
  } else if (
    navigator.userAgent.indexOf("iPad") > 0 ||
    navigator.userAgent.indexOf("Android") > 0
  ) {
    // device = tab
    isTab = true;
  } else {
    // device = pc
    pagetopButton.classList.remove("is-hidden");
  }
};

window.onscroll = () => {
  initDevice();
  pagetopButton = document.getElementById("top-pagetop");
  if (isSP || isTab) {
    let scroll = window.pageYOffset;
    if (scroll < 500) {
      pagetopButton.classList.add("is-hidden");
    } else {
      pagetopButton.classList.remove("is-hidden");
    }
  }
};

const initHamburger = () => {
  let hamburgerIcon = document.getElementById("navbar__hamburger");
  hamburgerModal = document.getElementById("top-hamburger");
  hamburgerIcon.addEventListener("click", showHamburger, false);
};

const showHamburger = () => {
  hamburgerModal.classList.remove("is-hidden");
  document.body.style.overflow = "hidden";
  const hamburgerClose = document.getElementById("top-hamburger__close");
  hamburgerClose.addEventListener("click", hideHamburger, false);
};

const hideHamburger = () => {
  document.body.style.overflow = "visible";
  hamburgerModal.classList.add("is-hidden");
};

window.addEventListener("load", () => {
  initDevice();
  initHamburger();
});
